import React, { useEffect, useState } from "react";
import Header from "../components/layout/Header";
import MiddleBanner from "../components/common/MiddleBanner";
import Space from "../components/common/Space";
import SlickSlider from "../components/common/SlickSlider";
import Footer from "../components/layout/Footer";
import Img2 from "../assets/images/home/Img2.webp";
import PrayerTime from "../assets/images/home/PrayerTime.png";
import BgImage from "../assets/images/home/MiddleBanner.webp";
import UpcomingEvents from "../components/UpcomingEvents/UpcomingEvents";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {/* Bootstrap Modal */}
      {/* <div className={`modal fade ${isModalOpen ? 'show' : ''}`} style={{ display: isModalOpen ? 'block' : 'none' }} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden={!isModalOpen}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content" style={{ width: '50vw' }}>
            <div className="modal-header py-0 position-relative" style={{ border: 'none' }}>
              <button type="button" className="btn-close rounded-circle position-absolute top-0 end-0 mt-4 me-3" onClick={handleCloseModal} aria-label="Close" style={{ backgroundColor: 'white', opacity:'1', zIndex: 1050 }}>
              </button>
            </div>
            <div className="modal-body p-1">
              <img src="/eventimages/33.jpg" alt="Event" className="img-fluid" style={{ maxWidth: '100%', height: 'auto' }} />
            </div>
          </div>
        </div>
      </div> */}

      <Helmet>
        <title>Home | Huntsville Islamic Center</title>
      </Helmet>
      <section id="website-responsive" className="px-0">
        <section className="container-fluid bg-home-banner">
          <Header />
          <div className="fs-banner fs-banner-title text-white fw-500">
            <span className="fs-banner-sub-title text-ss-secondary text-uppercase lh-sm"> Welcome to </span> <br />
            Huntsville <br /> Islamic Center
          </div>
        </section>
        <section className="">
          <div className="py-4 py-md-5" />
          <section className="container-fluid">
            <div className="col-11 col-lg-8 mx-auto">
              <div className="fs-ss-24 fw-500 text-center lh-lg">
                And those who are patient, seeking the countenance of their Lord, and establish prayer and spend from what We have provided for them secretly and publicly and prevent evil with good – those will have the good consequence of [this] home. [13:22]
              </div>
            </div>
          </section>
          <div className="py-4 py-md-5" />
          <section id="masjid" className="container-fluid text-barlow">
            <div className="col-11 col-lg-8 mx-auto shadow-ss-1">
              <iframe
                src="https://masjidbox.com/prayer-times/huntsville-islamic-center"
                title="Masjidbox Widget"
                style={{ width: '100%', height: '600px' }}
              />
            </div>
          </section>
        </section>
        <div className="py-4 py-md-5" />
        <UpcomingEvents />
        <div className="py-4 my-2 my-md-2 py-md-5" />
        <section className="container-fluid">
          <div className="col-11 col-lg-9 mx-auto lh-lg">
            <div className="fs-ss-18 fw-400 text-center">
              Join us in experiencing the vibrant community spirit at Huntsville Islamic Center. Our upcoming events celebrate faith, culture, and connection, offering opportunities to gather, learn, and grow together. From cultural activities to significant religious observances, these events inspire and strengthen bonds within our community. Stay updated, get involved, and enrich your spiritual journey through gatherings that unite us in shared values and lasting connections.
            </div>
          </div>
        </section>
        <div className="py-4 py-md-5" />
        <section className="container-fluid bg-mosque">
          <div className="pb-4 text-center">
            <div className="fs-ss-40 fw-600">
              Guidance & Inspiration
            </div>
            <a href="https://www.youtube.com/channel/UCq1M6eOaFxLc_yh22EgcYhA" target="_blank" className="text-decoration-none hover-ss-secondary fs-ss-22 fw-500">
              Visit Our Channel for Latest Videos
            </a>
          </div>
          <div className="row justify-content-center">
            <div className="col-11 col-md-8 text-center">
              <iframe className="w-video bg-ss-primary p-2 shadow-ss-1-video rounded-3" src="https://www.youtube.com/embed/vkZlchkfr3E?si=M-P-MkhytZWMPTP4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen />
            </div>
          </div>
          <div className="py-4 py-md-5" />
        </section>
        <MiddleBanner
          BgImage={BgImage}
          Title="Together in Faith"
          SubTitle="Together in Service"
          BtnTitle="Become a Member"
        />
        <div className="py-4 py-md-5" />
        <section className="container-fluid">
          <div className="pb-4 text-center">
            <div className="fs-ss-40 fw-600">
              Facility Tour
            </div>
            <div className="text-ss-secondary fs-ss-22 fw-500">
              A Glimpse into Our Community Life
            </div>
          </div>
          <div className="row justify-content-cnter">
            <SlickSlider />
          </div>
        </section>
        <div className="py-4 py-md-5" />
        <section id="donate" className="container-fluid px-0">
          <div className="row justify-content-center justify-content-md-start align-item-center gx-0 ">
            <div className="col-11 col-md-7 mb-5 d-none d-md-block">
              <img src={Img2} className="w-100" alt="" />
            </div>
            <div className="col-11 col-md-4 py-5 py-md-0 bg-above-footer rounded-3 text-center shadow-ss-1-video">
              <div className="py-md-5" />
              <div className="py-lg-5" />
              <div className="fs-middle-banner fw-500 text-ss-secondary lh-sm text-hidayatullah">
                Empowering
              </div>
              <div className="fs-sub-middle-banner fw-500 text-white mb-4">
                Lives Through Generosity
              </div>
              <a target="_blank" href="https://checkout.square.site/merchant/WKRSEAKCJHK6E/checkout/VHNIUNBCZZT77TEG77DKXULW" className="text-decoration-none bg-ss-secondary rounded-5 pt-1 pb-2 border-0 btn-padding-donate hover-ss-donate fs-ss-24 px-5 text-dark fw-600">
                Donate
              </a>
            </div>
          </div>
        </section>
        <div className="py-4 py-md-5" />
        <Footer />
      </section>
    </>
  );
}

export default Home;